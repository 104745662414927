<template>
  <div class="pt-2">
    <h3 class="mb-4">{{ $t('bookkeeping.bookkeepingManagement.sentDocuments.header') }}</h3>
    <Table
      :columns="columns"
      :data="paginatedData"
      show-index
      rounded
      border
      @row-click="handleRowClick(paginatedData[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-sendingDate="{ rowData: { sendingDate } }">
        {{ formatDate(sendingDate) }}
      </template>
      <template #filter-sendingDate>
        <el-date-picker
          v-model="dateRange"
          format="dd MMMM yyyy"
          type="daterange"
          :start-placeholder="$t('commons.startDate')"
          :end-placeholder="$t('commons.endDate')"
        >
        </el-date-picker>
      </template>
      <template #cell-status="{ rowData: { status } }">
        <Tag :type="getStatusColor(status)">
          {{ $t(`bookkeeping.bookkeepingManagement.batchStatus.${status}`) }}
        </Tag>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="filteredTableData.length && filteredTableData.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import { Table, Tag } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { getStatusColor } from '../batch';
import { formatDate } from '../formatters';

const TABLE_HEADERS = {
  SENDING_DATE: 'sendingDate',
  SENT_BY: 'sentBy',
  SENT_TO: 'sentTo',
  SUPPLIERS_NUMBER: 'suppliersNumber',
  DOCUMENTS_NUMBER: 'documentsNumber',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
};

export default {
  components: { Table, Tag },
  props: {
    documentBatches: { type: Array, required: true },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency();

    return {
      formatCentsToCurrency,
      getStatusColor,
      pageSize: 50,
      currentPageIndex: ref(0),
      dateRange: ref(null),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader('sendingDate'),
          key: TABLE_HEADERS.SENDING_DATE,
          filterActive: !!this.dateRange,
        },
        {
          header: this.translateTableHeader('sentBy'),
          key: TABLE_HEADERS.SENT_BY,
        },
        {
          header: this.translateTableHeader('sentTo'),
          key: TABLE_HEADERS.SENT_TO,
        },
        {
          header: this.translateTableHeader('suppliersNumber'),
          key: TABLE_HEADERS.SUPPLIERS_NUMBER,
        },
        {
          header: this.translateTableHeader('documentsNumber'),
          key: TABLE_HEADERS.DOCUMENTS_NUMBER,
        },
        {
          header: this.translateTableHeader('status'),
          key: TABLE_HEADERS.STATUS,
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    filteredTableData() {
      return this.documentBatches
        .map((documentBatch) => {
          const username = documentBatch.createdBy
            ? `${documentBatch.createdBy.profile.firstName} ${documentBatch.createdBy.profile.lastName}`
            : this.$t('bookkeeping.bookkeepingManagement.automatically');

          return {
            id: documentBatch.id,
            [TABLE_HEADERS.SENDING_DATE]: documentBatch.createdAt,
            [TABLE_HEADERS.SENT_BY]: username,
            [TABLE_HEADERS.SENT_TO]: documentBatch.sentTo,
            [TABLE_HEADERS.SUPPLIERS_NUMBER]: '-',
            [TABLE_HEADERS.DOCUMENTS_NUMBER]: documentBatch.refs.length,
            [TABLE_HEADERS.STATUS]: documentBatch.status,
          };
        })
        .filter(
          ({ sendingDate }) => !this.dateRange || (this.dateRange[0] <= sendingDate && sendingDate <= this.dateRange[1])
        )
        .sort((a, b) => b[TABLE_HEADERS.SENDING_DATE] - a[TABLE_HEADERS.SENDING_DATE]);
    },
    paginatedData() {
      return this.filteredTableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
  },
  methods: {
    handleRowClick({ id }) {
      this.$router.push({ name: 'bookkeepingBatch', params: { id } });
    },
    formatDate,
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.sentDocuments.table.headers.${columnKey}`);
    },
  },
};
</script>
