<template>
  <div class="pt-2">
    <h3 class="mb-4">{{ $t('bookkeeping.bookkeepingManagement.sentBillings.header') }}</h3>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="paginatedData"
      show-index
      rounded
      border
      @row-click="handleRowClick(paginatedData[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-sendingDate="{ rowData: { sendingDate } }">
        {{ formatDate(sendingDate) }}
      </template>
      <template #filter-sendingDate>
        <el-date-picker
          v-model="dateRange"
          format="dd MMMM yyyy"
          type="daterange"
          :start-placeholder="$t('commons.startDate')"
          :end-placeholder="$t('commons.endDate')"
        >
        </el-date-picker>
      </template>
      <template #cell-status="{ rowData: { status } }">
        <Tag :type="getStatusColor(status)">
          {{ $t(`bookkeeping.bookkeepingManagement.batchStatus.${status}`) }}
        </Tag>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="filteredTableData.length && filteredTableData.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { flatten, uniq } from 'ramda';

import { Table, Tag } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useBusinessByNames } from '@/modules/business/compositions/business';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useBillingsBatch } from '../composition/billing';
import { getStatusColor } from '../batch';
import { formatDate } from '../formatters';
import { SENDING_METHOD } from '../constants';

const TABLE_HEADERS = {
  SENDING_DATE: 'sendingDate',
  SENT_BY: 'sentBy',
  SENT_TO: 'sentTo',
  SUPPLIERS_NUMBER: 'suppliersNumber',
  DOCUMENTS_NUMBER: 'documentsNumber',
  NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
};

export default {
  components: { Table, Tag },
  props: {
    billingBatches: { type: Array, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();

    const { billings, loading: billingsLoading } = useBillingsBatch(
      computed(() => ({
        ids: flatten(props.billingBatches.reduce((acc, billingBatch) => acc.concat(billingBatch.refs), [])),
        businessId: currentTenant.value.id,
      }))
    );

    const bookkeeperIds = computed(() =>
      props.billingBatches
        .filter(({ sendingMethod }) => sendingMethod === SENDING_METHOD.PLATFORM)
        .map(({ sentTo }) => sentTo)
    );
    const { result: bookkeepers } = useBusinessByNames({ businessIds: bookkeeperIds });

    return {
      formatCentsToCurrency,
      billings,
      loading: billingsLoading,
      getStatusColor,
      pageSize: 50,
      currentPageIndex: ref(0),
      dateRange: ref(null),
      bookkeepers,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader('sendingDate'),
          key: TABLE_HEADERS.SENDING_DATE,
          filterActive: !!this.dateRange,
        },
        {
          header: this.translateTableHeader('sentBy'),
          key: TABLE_HEADERS.SENT_BY,
        },
        {
          header: this.translateTableHeader('sentTo'),
          key: TABLE_HEADERS.SENT_TO,
        },
        {
          header: this.translateTableHeader('suppliersNumber'),
          key: TABLE_HEADERS.SUPPLIERS_NUMBER,
        },
        {
          header: this.translateTableHeader('documentsNumber'),
          key: TABLE_HEADERS.DOCUMENTS_NUMBER,
        },
        {
          header: this.translateTableHeader('netAmount'),
          key: TABLE_HEADERS.NET_AMOUNT,
        },
        {
          header: this.translateTableHeader('tax'),
          key: TABLE_HEADERS.TAX_AMOUNT,
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
        {
          header: this.translateTableHeader('status'),
          key: TABLE_HEADERS.STATUS,
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    filteredTableData() {
      return !this.billings.length
        ? []
        : this.billingBatches
            .map((billingBatch) => {
              const username = billingBatch.createdBy
                ? `${billingBatch.createdBy.profile.firstName} ${billingBatch.createdBy.profile.lastName}`
                : this.$t('bookkeeping.bookkeepingManagement.automatically');
              const relevantBillings = this.billings.filter((billing) => billingBatch.refs.includes(billing.id));
              const supplierIds = uniq(relevantBillings.map((billing) => billing.supplierId));

              const netAmount = relevantBillings.reduce((sum, billing) => sum + billing.netAmount, 0);
              const taxAmount = relevantBillings.reduce((sum, billing) => sum + billing.taxAmount, 0);
              const totalAmount = relevantBillings.reduce((sum, billing) => sum + billing.totalAmount, 0);

              const sentTo =
                billingBatch.sendingMethod === SENDING_METHOD.PLATFORM
                  ? this.bookkeepers.find(({ id }) => id === billingBatch.sentTo)?.name
                  : billingBatch.sentTo;

              return {
                id: billingBatch.id,
                [TABLE_HEADERS.SENDING_DATE]: billingBatch.createdAt,
                [TABLE_HEADERS.SENT_BY]: username,
                [TABLE_HEADERS.SENT_TO]: sentTo,
                [TABLE_HEADERS.SUPPLIERS_NUMBER]: supplierIds.length,
                [TABLE_HEADERS.DOCUMENTS_NUMBER]: billingBatch.refs.length,
                [TABLE_HEADERS.NET_AMOUNT]: this.formatMoney(netAmount),
                [TABLE_HEADERS.TAX_AMOUNT]: this.formatMoney(taxAmount),
                [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(totalAmount),
                [TABLE_HEADERS.STATUS]: billingBatch.status,
              };
            })
            .filter(
              ({ sendingDate }) =>
                !this.dateRange || (this.dateRange[0] <= sendingDate && sendingDate <= this.dateRange[1])
            )
            .sort((a, b) => b[TABLE_HEADERS.SENDING_DATE] - a[TABLE_HEADERS.SENDING_DATE]);
    },
    paginatedData() {
      return this.filteredTableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
  },
  methods: {
    handleRowClick({ id }) {
      this.$router.push({ name: 'bookkeepingBatch', params: { id } });
    },
    formatDate,
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.sentBillings.table.headers.${columnKey}`);
    },
  },
};
</script>
