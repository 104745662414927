<template>
  <div>
    <div class="pt-2">
      <h3 class="mb-4">{{ $t('bookkeeping.bookkeepingManagement.sentPayments.header') }}</h3>
    </div>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="paginatedData"
      show-index
      rounded
      border
      @row-click="handleRowClick(paginatedData[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-sendingDate="{ rowData: { sendingDate } }">
        {{ formatDate(sendingDate) }}
      </template>
      <template #filter-sendingDate>
        <el-date-picker
          v-model="dateRange"
          format="dd MMMM yyyy"
          type="daterange"
          :start-placeholder="$t('commons.startDate')"
          :end-placeholder="$t('commons.endDate')"
        >
        </el-date-picker>
      </template>
      <template #cell-status="{ rowData: { status } }">
        <Tag :type="getStatusColor(status)">
          {{ $t(`bookkeeping.bookkeepingManagement.batchStatus.${status}`) }}
        </Tag>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="filteredTableData.length && filteredTableData.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { flatten, uniq } from 'ramda';

import { Table, Tag } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { usePaymentsBatch } from '../composition/payment';
import { getStatusColor } from '../batch';
import { formatDate } from '../formatters';

const TABLE_HEADERS = {
  SENDING_DATE: 'sendingDate',
  SENT_BY: 'sentBy',
  SENT_TO: 'sentTo',
  SUPPLIERS_NUMBER: 'suppliersNumber',
  DOCUMENTS_NUMBER: 'documentsNumber',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
};

export default {
  components: { Table, Tag },
  props: {
    paymentBatches: { type: Array, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { formatToCurrency } = useCurrency();
    const { payments, loading } = usePaymentsBatch(
      computed(() => ({
        ids: flatten(props.paymentBatches.reduce((acc, paymentBatch) => acc.concat(paymentBatch?.refs), [])),
        businessId: currentTenant.value.id,
      }))
    );

    return {
      formatToCurrency,
      payments,
      loading,
      getStatusColor,
      pageSize: 50,
      currentPageIndex: ref(0),
      dateRange: ref(null),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateTableHeader('sendingDate'),
          key: TABLE_HEADERS.SENDING_DATE,
          filterActive: !!this.dateRange,
        },
        {
          header: this.translateTableHeader('sentBy'),
          key: TABLE_HEADERS.SENT_BY,
        },
        {
          header: this.translateTableHeader('sentTo'),
          key: TABLE_HEADERS.SENT_TO,
        },
        {
          header: this.translateTableHeader('suppliersNumber'),
          key: TABLE_HEADERS.SUPPLIERS_NUMBER,
        },
        {
          header: this.translateTableHeader('documentsNumber'),
          key: TABLE_HEADERS.DOCUMENTS_NUMBER,
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
        {
          header: this.translateTableHeader('status'),
          key: TABLE_HEADERS.STATUS,
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    filteredTableData() {
      return !this.payments.length
        ? []
        : this.paymentBatches
            .map((paymentBatch) => {
              const username = paymentBatch.createdBy
                ? `${paymentBatch.createdBy.profile.firstName} ${paymentBatch.createdBy.profile.lastName}`
                : this.$t('bookkeeping.bookkeepingManagement.automatically');
              const relevantPayments = this.payments.filter((payment) => paymentBatch.refs.includes(payment.id));
              const supplierIds = uniq(relevantPayments.map((payment) => payment.supplierId));

              const totalAmount = relevantPayments.reduce(
                (sum, { amount, isRefund }) => sum + amount * (isRefund ? -1 : 1),
                0
              );

              return {
                id: paymentBatch.id,
                [TABLE_HEADERS.SENDING_DATE]: paymentBatch.createdAt,
                [TABLE_HEADERS.SENT_BY]: username,
                [TABLE_HEADERS.SENT_TO]: paymentBatch.sentTo,
                [TABLE_HEADERS.SUPPLIERS_NUMBER]: supplierIds.length,
                [TABLE_HEADERS.DOCUMENTS_NUMBER]: paymentBatch.refs.length,
                [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(totalAmount),
                [TABLE_HEADERS.STATUS]: paymentBatch.status,
              };
            })
            .filter(
              ({ sendingDate }) =>
                !this.dateRange || (this.dateRange[0] <= sendingDate && sendingDate <= this.dateRange[1])
            )
            .sort((a, b) => b[TABLE_HEADERS.SENDING_DATE] - a[TABLE_HEADERS.SENDING_DATE]);
    },
    paginatedData() {
      return this.filteredTableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
  },
  methods: {
    handleRowClick({ id }) {
      this.$router.push({ name: 'bookkeepingBatch', params: { id } });
    },
    formatDate,
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.sentPayments.table.headers.${columnKey}`);
    },
  },
};
</script>
